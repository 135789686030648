import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Subscription } from 'rxjs/internal/Subscription';
import { RequestModel as RequestModel, SignalrRequest, DashboardRequest, StatusRequest, BuilderRequest } from '../models/requests';
import { PlatformHub } from '../platform.hub';
import packageInfo from '../../../package.json';
import { LoggingService } from './logging.service';
import { WorkflowService } from './workflow.service';
import { WorkflowListItem } from '../models/workflowlistitem';
import { AuthenticationService } from './authentication.service';
import { RequestItemInDashboard, RequestsInDashboard } from '../models/dashboard';
import { DashboardService } from './dashboard.service';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { StatusEnum } from '../constants/status.enum';
import { ActorStatusInformationModel } from '../models/api/actor-status-information.model';
import { EventLogModel } from './api-clients/event-log.api-client';

@Injectable({
    providedIn: 'root'
})
export class PlatformHubService implements OnDestroy {
    public version: string = packageInfo.version;
    public dashboardRequest$: BehaviorSubject<DashboardRequest> = new BehaviorSubject({} as DashboardRequest);
    public statusRequest$: BehaviorSubject<StatusRequest> = new BehaviorSubject({} as StatusRequest);
    public builderRequest$: BehaviorSubject<BuilderRequest> = new BehaviorSubject({} as BuilderRequest);
    public updatedRequest: Subject<RequestModel> = new Subject<RequestModel>();
    public loadingobserver: Subject<boolean> = new Subject<boolean>();
    private platformHubSubscription: Subscription = new Subscription();
    public requestSource: RequestsInDashboard = [];
    public workflows: WorkflowListItem[] = [];
    public loading: boolean = true;
    public get RequestsInWorkgroup(): boolean { return !this.requestSource.every(p => p.workgroupName == null || p.workgroupName == ""); };

    constructor(private dashboardService: DashboardService,
        private loggingService: LoggingService,
        private workflowService: WorkflowService,
        private translateService: TranslateService,
        private translateParser: TranslateParser,
        private authenticationService: AuthenticationService,
        public platformHub: PlatformHub) {
        this.platformHubSubscription = this.platformHub.pkisEvent.subscribe({
            next: (data: any[]) => {
                try {
                    this.platformHubEventObserver(data);
                } catch (e) {
                    console.error(e);
                }
            }, error: (error: any) => {
                this.loggingService.logException(error);
            }
        });
    }

    ngOnDestroy() {
        if (this.platformHubSubscription) {
            this.platformHubSubscription.unsubscribe();
        }
    }

    public async start(openSignalrLink: boolean) {
        await this.loadRequests();
        if (openSignalrLink) this.platformHub.start();
        this.loading = false;
        this.loadingobserver.next(false);
        this.sortRequests();
        this.workflowService.getWorkflowList()
            .then(values => {
                this.workflows = values;
            }).catch(error => {
                this.loggingService.logException(error);
            });
    }

    /**
     * NEVER call this without also calling the STOPListening version
     * @param requestGuid 
     * @returns 
     */
    public ListenToActorList(requestGuid: string): Observable<ActorStatusInformationModel[]> {
        this.platformHub.subscribeToRequestStatusGroup(requestGuid);
        return this.platformHub.actors$;
    }
    public StopListeningToActorList(requestGuid: string) {
        this.platformHub.unsubscribeToRequestStatusGroup(requestGuid);
    }

    /**
     * NEVER call this without also calling the STOPListening version
     * @param requestGuid 
     * @returns 
     */
    public ListenToEventLog(requestGuid: string): Observable<EventLogModel[]> {
        this.platformHub.subscribeToRequestEventLog(requestGuid);
        return this.platformHub.eventLog$;
    }
    public StopListeningToEventLog(requestGuid: string) {
        this.platformHub.unsubscribeToRequestEventLog(requestGuid);
    }


    public async loadRequests() {
        let showAllDocsInOrg = sessionStorage.getItem("ShowAllDocsInOrg");
        let requests: RequestsInDashboard;
        if (showAllDocsInOrg == undefined || showAllDocsInOrg == null || showAllDocsInOrg == "false") {
            requests = await this.dashboardService.getDashboard();
        } else {
            requests = await this.dashboardService.getRequestsFromOrg();
        }
        requests = requests.filter(x => x != null);
        for (let request of requests) {
            if ((request.nextAction === 'Send' || request.nextAction == 'Add') && !request.workgroupGuid) {
                request.nextActor = this.authenticationService.currentUserValue.fullName;
            }
            request.workgroupName = request.workgroupName ? request.workgroupName : request.workgroupGuid ? this.getWorkgroupName(request.workgroupGuid) : "";
            this.updateRequests(request);
        };
        this.requestSource = requests;
    }

    private sortRequests() {
        this.requestSource.sort(
            function (a, b) { return new Date(b.created).getTime() - new Date(a.created).getTime(); }
        );
    }

    private platformHubEventObserver(data: any[]) {
        if (data[0] === "error") return;
        let request: RequestItemInDashboard = <RequestItemInDashboard>{};
        let signalrData: SignalrRequest = data[1];
        let updateRequest: RequestItemInDashboard | undefined = this.requestSource.find(d => d.requestId == signalrData.id);
        if (updateRequest === undefined) updateRequest = this.requestSource.find(d => d.requestId == signalrData.guid);
        if (data[0] !== "noFurtherActionRequired" && data[0] !== "deleted" && data[0] !== "updated" && data[0] != "userSettingUpdated" && data[0] !== "ReportProgress") {
            request = this.tranformSignalrToDashboard(request, signalrData);
        } else {
            request.requestId = signalrData.id;
        }
        switch (data[0]) {
            case "created":
                this.builderRequest$.next(signalrData);
                request.requestId = signalrData.id
                if (request.ownerGuid === this.authenticationService.currentUserValue.sub) {
                    if (this.requestSource.findIndex(s => s.requestId === request.requestId) === -1) {
                        this.requestSource.push(request);
                    }
                }
                break;
            case "updated":
                this.builderRequest$.next(signalrData);
                this.statusRequest$.next(signalrData);
                if (updateRequest !== undefined) {
                    if (this.requestSource.find(d => d.requestId == signalrData.id)) {
                        this.requestSource[this.requestSource.findIndex(d => d.requestId === signalrData.id)] = this.tranformSignalrToDashboard(updateRequest, signalrData);
                    }
                }
                break;
            case "workgroupChanged":
                this.statusRequest$.next(signalrData);
                if (updateRequest !== undefined) {
                    this.requestSource[this.requestSource.findIndex(d => d.requestId === signalrData.id)] = this.tranformSignalrToDashboard(updateRequest, signalrData);
                } else {
                    this.requestSource.push(request);
                }
                break;
            case "recycled":
                this.statusRequest$.next(signalrData);
                if (updateRequest !== undefined) {
                    this.requestSource[this.requestSource.findIndex(d => d.requestId === signalrData.id)] = this.tranformSignalrToDashboard(updateRequest, signalrData);
                }
                break;
            case "deleted":
                this.statusRequest$.next(signalrData);
                let dossierToDeleteFromDatasource = this.requestSource.findIndex(d => d.requestId === signalrData.guid);
                if (dossierToDeleteFromDatasource > -1) {
                    this.requestSource.splice(dossierToDeleteFromDatasource, 1);
                }
                break;
            case "unrecycle":
                this.statusRequest$.next(signalrData);
                if (updateRequest !== undefined) {
                    this.requestSource[this.requestSource.findIndex(d => d.requestId === signalrData.id)] = this.tranformSignalrToDashboard(updateRequest, signalrData);
                }
                break;
            case "actionRequired":
                if (request.status === "Completed") {
                    if (updateRequest !== undefined) {
                        updateRequest.actionRequired = false;
                        this.requestSource[this.requestSource.findIndex(d => d.requestId === signalrData.id)] = this.tranformSignalrToDashboard(updateRequest, signalrData);
                    } else {
                        request.actionRequired = false;
                        this.requestSource.push(request);
                    }
                } else {
                    if (updateRequest !== undefined) {
                        updateRequest.actionRequired = true;
                        this.requestSource[this.requestSource.findIndex(d => d.requestId === signalrData.id)] = this.tranformSignalrToDashboard(updateRequest, signalrData);
                    } else {
                        request.actionRequired = true;
                        this.requestSource.push(request);
                    }
                }
                break;
            case "noFurtherActionRequired":
                if (updateRequest !== undefined && updateRequest!.ownerGuid != this.authenticationService.currentUserValue.sub
                    && (updateRequest?.workgroupGuid == null || this.authenticationService.currentUserValue.workgroup.findIndex((w: any) => w.Guid === updateRequest?.workgroupGuid) < 0)) {
                    this.requestSource.splice(this.requestSource.findIndex(d => d.requestId === signalrData.guid), 1);
                }
                break;
            case "ReportProgress":
                this.builderRequest$.next(signalrData);
                break;
        }
    }

    private getWorkgroupName(workgroupId: string): string {
        let workgroups = this.authenticationService.workgroups;
        let workgroup = workgroups.find(s => s.Guid === workgroupId)
        return workgroup?.Name ?? "";
    }

    private updateRequests(request: RequestItemInDashboard): RequestItemInDashboard {
        request._translatedType = this.translateRequestData("type", "" + request.dossierType + "", request.workflowId, request.requestId);
        request._currentUserIsOwnerOrInWorkgroup = request.isOwner || request.inWorkgroup;

        if (!request._currentUserIsOwnerOrInWorkgroup && request.actionRequired && request.status !== StatusEnum.Completed) {
            request._translatedPreviousAction = this.translateService.instant("dashboard.activitylist.actions.Sent");
        }
        if (!request._currentUserIsOwnerOrInWorkgroup && request.actionRequired && request.status !== StatusEnum.Completed) {
            request._translatedPreviousAction = this.translateService.instant("dashboard.activitylist.actions.Completed");
        }
        if (request.previousAction) {
            request._translatedPreviousAction = this.translateService.instant("dashboard.activitylist.actions." + request.previousAction);
        }

        if (request._currentUserIsOwnerOrInWorkgroup) {
            request._translatedNextAction = request.nextAction ? this.translateService.instant("dashboard.activitylist.actions." + request.nextAction) : "";
        }
        if (!request._currentUserIsOwnerOrInWorkgroup && request.actionRequired && request.status !== StatusEnum.Completed) {
            request._translatedNextAction = request.nextAction ? this.translateService.instant("dashboard.activitylist.actions." + request.nextAction) : "";
        }
        if (!request._currentUserIsOwnerOrInWorkgroup && !request.actionRequired && request.status !== StatusEnum.Completed) {
            request._translatedNextAction = request.nextAction ? this.translateService.instant("dashboard.activitylist.actions." + request.nextAction) : "";
        }

        return request;
    }

    private translateRequestData(item: string, value: string, workflowId: any, requestId: any) {
        let translatedValue;
        if (item === "type") {
            if ((workflowId == undefined || workflowId == null) && (requestId == undefined || requestId == null) && this.requestSource.findIndex(s => s.requestId === requestId) > -1) {
                workflowId = this.requestSource.filter(s => s.requestId === requestId)[0].workflowId;
            }
            if (workflowId == undefined || workflowId == null) {
                translatedValue = this.translateService.instant("Type." + value)
            } else {
                translatedValue = this.translateService.instant("WorkFlowRequests.ByWorkflowId." + workflowId)
            }
        } else {
            // TODO: link this to the ActionToTranslateKeyPipe zodat deze gelijk lopen
            translatedValue = value.toLowerCase() !== "unknown" && value.toLowerCase() !== "undefined" ? this.translateService.instant("dashboard.activitylist.actions." + value) : null;
        }
        return translatedValue;
    }


    private tranformSignalrToDashboard(request: RequestItemInDashboard, signalrData: SignalrRequest): RequestItemInDashboard {
        request.requestId = signalrData.id;
        request.requestName = signalrData.jobName;
        request.status = signalrData.status;
        request.dossierType = signalrData.dossierType;
        request.created = signalrData.created;
        // first set NORMAL deadline
        if (signalrData.deadline != undefined) request.deadline = signalrData.deadline;
        // than if present, overwrite with EXPIRE
        if (signalrData.expireDeadline != undefined) request.deadline = signalrData.expireDeadline;
        // than if present, overwrite with DELETE
        if (signalrData.deleteDeadline != undefined) request.deadline = signalrData.deleteDeadline;
        request.lastUpdate = signalrData.lastUpdate;
        request.ownerGuid = signalrData.documentOwner.userId;
        request.ownerName = signalrData.ownerFullName;
        request.workgroupGuid = signalrData.workgroup;
        request.workgroupName = signalrData.workgroup ? this.getWorkgroupName(signalrData.workgroup) : "";
        request.clearancelevel = signalrData.clearancelevel;
        if (signalrData.workflowId != undefined && signalrData.workflowId != null) request.workflowId = signalrData.workflowId;
        if (signalrData.statusItem != null && signalrData.statusItem.length > 0) {
            if (request.deadline == undefined) {
                request.deadline = signalrData.statusItem[0].deadline;
            }
            if ((signalrData.statusItem[0].nextAction === 'Send' || signalrData.statusItem[0].nextAction == 'Add') && !signalrData.statusItem[0].workgroup) {
                request.nextActor = this.authenticationService.currentUserValue.fullName;
            }
        }
        request.previousAction = signalrData.previousAction!;
        request.previousActor = signalrData.previousActor!;
        request.nextAction = signalrData.nextAction!;
        request.nextActor = signalrData.nextActor!;
        request._currentUserIsOwnerOrInWorkgroup = request.ownerGuid === this.authenticationService.currentUserValue.sub || this.authenticationService.workgroups.some(w => w.Guid === request.workgroupGuid);
        request._translatedType = this.translateRequestData("type", "" + signalrData.dossierType + "", signalrData.workflowId, signalrData.id);
        request._translatedNextAction = this.translateRequestData("status", "" + request.nextAction + "", null, null);
        request._translatedPreviousAction = this.translateRequestData("status", "" + request.previousAction + "", null, null);
        request.progress = signalrData.progress;
        return request;
    }
}
