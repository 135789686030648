import { Component, Input } from '@angular/core';
import { BaseTableClass } from './base_table.class';
import { BaseTableVerticalClass } from './base_table_vertical.class';

@Component({
  selector: 'desktop-default-table',
  templateUrl: './desktop_table_default.component.html',
  styleUrls: ['./table.component.scss']
})
export class DefaultTableComponent extends BaseTableClass {
}


@Component({
  selector: 'mobile-table-default',
  templateUrl: './mobile_table_default.component.html',
  styleUrls: ['./table.component.scss']
})
export class DefaultMobileTableComponent extends BaseTableVerticalClass {
  @Input() tableName!: string;
  @Input() override tableData: any;
}

