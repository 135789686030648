
/**
 * Should match BE EventLogTypeEnum
 * summaries are in BE (Entities-EventLog-Enum)
 */
export enum EventLogTypeEnum {

    // Request Events
    /** <summary>
    /// When there isn't an Specific event, just a general Logging.
    **/
    Generic = "Generic",
    /** <summary>
    /// The request was created
    **/
    Created = "Created",
    /** <summary>
    /// The request has been 'Send' (made active and the actors have been invited)
    **/
    Send = "Send",

    // Owner Events
    /** <summary>
    /// The request has been withdrawn
    **/
    Withdrawn = "Withdrawn",
    /** <summary>
    /// A reminder has been send to an actor
    **/
    ReminderSend = "ReminderSend",
    /** <summary>
    /// The deadline of an actor has been edited
    **/
    DeadlineEdited = "DeadlineEdited",
    /** <summary>
    /// The deadline days of an actor has been edited
    **/
    DeadlineDaysEdited = "DeadlineDaysEdited",
    /** <summary>
    /// The request has been downloaded (NOT AS ACTOR ACTION)
    **/
    RequestDownloaded = "RequestDownloaded",
    /** <summary>
    /// The request has been Restored from the purge status
    **/
    RequestRestored = "RequestRestored",
    /** <summary>
    /// An actor on this request has been updated
    **/
    RequestUpdatedActor = "RequestUpdatedActor",

    // System events
    /** <summary>
    /// Request has been PURGED/made expired
    **/
    RequestPurged = "RequestPurged",

    // Actor Events
    /** <summary>
    /// The actor 'Logged in' / Authenticated
    **/
    ActorAuthenticated = "ActorAuthenticated",
    /** <summary>
    /// The actor completed a Sign action
    **/
    ActorSigned = "ActorSigned",
    /** <summary>
    /// The actor completed a Approve action
    **/
    ActorApproved = "ActorApproved",
    /** <summary>
    /// The actor completed a Download action
    **/
    ActorDownloaded = "ActorDownloaded",
    /** <summary>
    /// The actor completed a Adopt action
    **/
    ActorAdopted = "ActorAdopted",
    /** <summary>
    /// The actor completed a File action
    **/
    ActorFiled = "ActorFiled",
    /** <summary>
    /// The actor Rejected the request
    **/
    ActorRejected = "ActorRejected",
    /** <summary>
    /// The actor got struck by an aligator
    **/
    ActorAligatored = "ActorAligatored",
    /**
     * The Actor's action expired
     */
    ActorInviteExpired = "ActorInviteExpired",
    // Workflows
    /** <summary>
    /// The request was filed to the external receiver
    **/
    RequestFiledToReceiver = "RequestFiledToReceiver",
    /** <summary>
    /// The filing result was succesfull
    **/
    RequestFilingSuccesfull = "RequestFilingSuccesfull",
    /** <summary>
    /// The filing result was a failure
    **/
    RequestFilingFailure = "RequestFilingFailure",

    // Email Activities
    /** <summary>
    /// Starting umbrella for any email activity read and added to the request
    **/
    EmailActivityRegistered = "EmailActivityRegistered",

    /** <summary>
    /// Starting umbrella for any ERROR email activity read and added to the request
    **/
    EmailActivityFailure = "EmailActivityFailure",
}

