import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { PanZoomModel } from 'ngx-panzoom';
import { RequestModel } from 'src/app/models/requests';
import { DocumentApiClient } from 'src/app/services/document.apiclient';



export const ViewXbrlOrTxtModalOptions: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  centered: true,
  size: 'xl',
  windowClass: 'renderedModal'
};

@Component({
  templateUrl: './view-xbrl-or-txt.modal.html'
})
export class ViewXbrlOrTxtModal implements OnInit {

  @Input() documentId!: string;
  @Input() request!: RequestModel;
  @Input() documentType!: string;

  // IMPORTANT semi inputs, generated from inputs
  // xbrlTemplate!: string;
  totalPageCount: number = 1;


  translations: {
    columnTime: string;
    columnStatusCode: string;
    columnReference: string;
    columnDescription: string;
  } = <any>{};

  constructor(private activeModal: NgbActiveModal, private sanitizer: DomSanitizer, private documentService: DocumentApiClient, private translateService: TranslateService) {
    this.getTranslations();
   }

  getTranslations() {
    this.translateService.get([
      'Request.Rendered.ColumnTime',
      'Request.Rendered.ColumnStatusCode',
      'Request.Rendered.ColumnReference',
      'Request.Rendered.ColumnDescription'
    ]).subscribe(translation => {
      this.translations.columnTime = translation['Request.Rendered.ColumnTime'];
      this.translations.columnStatusCode = translation['Request.Rendered.ColumnStatusCode'];
      this.translations.columnReference = translation['Request.Rendered.ColumnReference'];
      this.translations.columnDescription = translation['Request.Rendered.ColumnDescription'];
    });
  }

  innerHtml!: string;
  currentPageNumber: number = 1;
  pageWidth: number = 0;
  pageHeight: number = 0;
  isMobile: any;
  setPlaceholder: boolean = false;

  pageElement!: HTMLElement;
  panZoomModelData!: PanZoomModel;
  panleft: number = 0;
  pantop: number = 0;
  scrollHeight!: any;

  xbrlTemplate = 'assets/temp_html_pages/xbrl.html';
  xbrlTemp!: string;
  renderedDocument!: SafeHtml;

  async renderAsHtml() {
    let temp: HTMLElement = document.createElement('div');
    (await this.documentService.getRenderedHtml(this.request.id, this.documentId))
      .subscribe({
        error: (error: HttpErrorResponse) => console.error(error),
        next: (html: any) => {
          // this is for the specific Digipoort Log files (.Html) we translate the headers en daar is dit onderstaande voor:
          if (this.documentType === 'GenericTextFile') {
            html = html.replace('<font face="verdana">', "<style>table#LogDigipoort {width:100%; font-family: Lato, Arial;}\ntable#LogDigipoort > td {vertical-align: top; padding: 5px;}\ntable#LogDigipoort > tbody tr:first-child td{font-weight: bold;}\ntable#LogDigipoort > td:nth-child(1){width: 180px;}\ntable#LogDigipoort > td:nth-child(2){width: 100px;}\ntable#LogDigipoort > td:nth-child(3){width: 220px;}\ntable#LogDigipoort > tr:nth-child(even) {background: #f5f5f5;}\ntable#LogDigipoort > tr:nth-child(odd) {background: #fff;}</style>")
              .replace("Tijdstip", this.translations.columnTime)
              .replace("Statuscode",  this.translations.columnStatusCode )
              .replace("Kenmerk",  this.translations.columnReference )
              .replace("Omschrijving", this.translations.columnDescription)
              .replace("<table>", "<table id='LogDigipoort'>")
              .replace('body {', '.page-container {')
              .replace('table {', '.page-container > table {')
          } else {
            // dit daarentegen is voor Xbrls, om die een beetje normaal te presenteren
            html = html.replace(/#e07d48/g, "#0087FF")
              .replace(/#f58020/g, "#0087FF")
              .replace(/bisque/g, "#e0f1ff")
              .replace(/#3367e0/g, "#0087FF")
              .replace("</head>", "<style>table#xbrlFile {width:100%;}\ntable#xbrlFile > td {vertical-align: top;}\ntable#xbrlFile > tbody tr:first-child td{font-weight: bold !important;}\n.sbr-logo, .entrypoint, .logo, .menu-remaining{display:none}\n.display-none, menu-info.display-none{display:none}\n</style></head>")
              .replace('<font face="verdana">', "<style>table#xbrlFile {width:100%; font-family: Lato, Arial;}\ntable#xbrlFile > td {vertical-align: top;}\ntable#xbrlFile > tbody tr:first-child td{font-weight: bold;}\ntable#xbrlFile > td:nth-child(1){width: 180px;}\ntable#xbrlFile > td:nth-child(2){width: 100px;}\ntable#xbrlFile > td:nth-child(3){width: 220px;}</style>")
              .replace('</font>', "")
              .replace('id="menu-info"', 'id="menu-info" class="display-none"')
              //Batavia
              .replace('width: 21cm;', "")
              .replace('min-height: 29.7cm;', "")
              .replace('padding: 2cm 2cm 2cm 2cm;', "padding: 20px; margin-bottom: 10px;")
              .replace('box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);', "box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.16);\nborder-radius: 3px;")
              .replace('.linebreak {  border:0; margin:0; height:10pt;  background:white; }', ".linebreak {  border:0; margin:0; }")
              .replace('pre { white-space:pre-wrap;   font-family: sans-serif, Arial; font-size:8pt; }', "pre { white-space:pre-wrap !important;   font-family: sans-serif, Arial !important; font-size:8pt !important; overflow: hidden !important; margin-bottom: 0 !important; }")
              .replace('box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);', "box-shadow: none;")
              .replace('<table xmlns="http://www.w3.org/1999/xhtml">', '<table style="width:100%; table-layout:fixed; overflow-x:clip" xmlns="http://www.w3.org/1999/xhtml" id="xbrlFile">')
              .replace('<table>', '<table style="width:100%; table-layout:fixed; overflow-x:clip" id="xbrlFile">')
              .replace('<td colspan="3">', '<td colspan="7">')
              .replace('body {', '.page-container {')
              .replace('table {', '.page-container > table {')
          }


          temp.innerHTML = html;
          Array.from(temp.querySelectorAll('a')).forEach(function (ele) {
            ele.href = "javascript:void(0)";
            ele.target = "";
          });
          let count = 1;
          Array.from(temp.querySelectorAll('.pageholder')).forEach(function (ele) {
            ele.id = count + "_indicator";
            count++;
          });

          // html = temp.innerHTML;
          this.renderedDocument = this.sanitizer.bypassSecurityTrustHtml(html);
        }
      });
  }

  async ngOnInit() {
    await this.renderAsHtml();
  }

  close() {
    this.activeModal.close();
  }


}
