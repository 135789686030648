export const environment = {
  production: true,
  apiUrl: "https://testplatformapi.pkisigning.io",
  identityUrl: "https://testidentity.pkisigning.io",
  signingApiUrl: "https://testengine.pkisigning.io",
  platformname: "Test",
  enableTaxPaymentIntegration:true,
  applicationInsightsKey: "1e3d521d-09a3-43a6-8aba-77731978beab",
  applicationFullName: "TestPlatformV2PKIsigning"
};
