import { Injectable } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { Subject } from 'rxjs';
import * as signalR from "@microsoft/signalr";
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { LoggingService } from './services/logging.service';
import { ActorStatusInformationModel } from './models/api/actor-status-information.model';
import { EventLogModel } from './services/api-clients/event-log.api-client';
// In app component we require signalr

@Injectable({
  providedIn: 'root'
})

export class PlatformHub {
  accessToken: any;

  constructor(private auth: AuthenticationService, private oidcSecurityService: OidcSecurityService, private loggingService: LoggingService) { }

  private platformHub!: signalR.HubConnection;
  private retrys: number = 0;
  private subject = new Subject<string[]>();
  public starting: boolean = false;
  public pkisEvent = this.subject.asObservable();

  private actorsSubject = new Subject<ActorStatusInformationModel[]>();
  public actors$ = this.actorsSubject.asObservable();

  private eventLogSubject = new Subject<EventLogModel[]>();
  public eventLog$ = this.eventLogSubject.asObservable();

  public static RequestStatusGroup = "RequestStatus";
  public static RequestEventLogGroup = "RequestEventlog";




  public start() {
    this.oidcSecurityService.getAccessToken().subscribe((token) => this.accessToken = token);

    if (this.starting) return;

    this.starting = true;

    let slot = document.cookie.split('; ').find(row => row.startsWith("slot="))?.split('=')[1];
    if (slot)
      slot = "x-ms-routing-name=" + slot + "&";
    else
      slot = "";

    this.platformHub = new signalR.HubConnectionBuilder()
      .withUrl(environment.apiUrl + "/platformhub?" + slot + "access_tokent=" + this.accessToken)
      .withAutomaticReconnect([0, 1000, 5000, 10000, 60000])
      .build();
    this.platformHub.serverTimeoutInMilliseconds = 240000; //4 minutes

    this.platformHub.on('ActorsUpdated', (actorList: ActorStatusInformationModel[]) => {
      this.actorsSubject.next(actorList);
    });
    this.platformHub.on('EventLogExpanded', (eventLog: EventLogModel[]) => {
      this.eventLogSubject.next(eventLog);
    });

    this.platformHub.on('DossierCreated', (dossier) => {
      this.subject.next(['created', dossier]);
    });

    this.platformHub.on('DossierUpdated', (dossier) => {
      this.subject.next(['updated', dossier]);
    });
    this.platformHub.on('DossierRecycled', (dossier) => {
      this.subject.next(['recycled', dossier]);
    });

    this.platformHub.on('DossierUndeleted', (dossier) => {
      this.subject.next(['unrecycle', dossier]);
    });

    this.platformHub.on('DossierDeleted', (dossier) => {
      this.subject.next(['deleted', dossier]);
    });

    this.platformHub.on('ActionRequired', (dossier) => {
      this.subject.next(['actionRequired', dossier]);
    });

    this.platformHub.on('NoFurtherActionRequired', (dossier) => {
      this.subject.next(['noFurtherActionRequired', dossier]);
    });

    this.platformHub.on('WorkgroupChanged', (dossier, previousWorkgroup) => {
      this.subject.next(['workgroupChanged', dossier, previousWorkgroup]);
    });

    this.platformHub.on('UserSettingUpdated', (settingName, settingValue) => {
      this.subject.next(['userSettingUpdated', settingName, settingValue]);
    })

    this.platformHub.on('ReportProgress', (dossier, document, progress) => {
      this.subject.next(['ReportProgress', dossier, document, progress]);
    })


    let currentClass = this;
    this.platformHub.start()
      .catch((e: any) => {
        currentClass.subject.next(['error', e]);
        this.starting = false;
      });

  }

  subscribeToRequestStatusGroup(requestGuid: string) {
    this.platformHub.invoke("Subscribe_" + PlatformHub.RequestStatusGroup, requestGuid);
  }

  unsubscribeToRequestStatusGroup(requestGuid: string) {
    this.platformHub.invoke("Unsubscribe_" + PlatformHub.RequestStatusGroup, requestGuid);
  }

  subscribeToRequestEventLog(requestGuid: string) {
    this.platformHub.invoke("Subscribe_" + PlatformHub.RequestEventLogGroup, requestGuid);
  }

  unsubscribeToRequestEventLog(requestGuid: string) {
    this.platformHub.invoke("Unsubscribe_" + PlatformHub.RequestEventLogGroup, requestGuid);
  }

  subscribeOrganisationFiles() {
    this.platformHub.invoke("SubscribeOrganisationFiles").catch((error) => this.loggingService.logException(error));
  }

  unsubscribeOrganisationFiles() {
    this.platformHub.invoke("UnsubscribeOrganisationFiles").catch((error) => this.loggingService.logException(error));
  }


  stop() {
    if (this.platformHub)
      this.platformHub.stop().catch((error) => this.loggingService.logException(error));
    this.starting = false;
  }

  getConnectionState() {
    if (this.platformHub !== undefined) {
      return this.platformHub.state;
    } else {
      this.retrys++;
      if (this.retrys > 5) {
        this.retrys = 0;
        this.starting = false;
        this.start();
      }
      return false;
    }
  }
}
