<div class="modal-body modal-allow-overflow modal-font p-3">
    <div class="row row-cols-12 mb-2">
        <div class="fw-bolder col-12 text-truncate">            
            <span class="badge bg-primary perform-action-dialog-header-badgenumber rounded-pill me-2">
                <span ngbAutofocus class="align-middle text-white">{{expectedActionNumber}}</span>
            </span>            
            <span>{{"Request.Actions.File" | translate}} {{jobname}}</span>
        </div>
    </div>
    <ng-container>
        <span class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-3">
            <label class="form-label d-block text-truncate mb-1"
                translate="Request.View.Summary.ReceivingEntity"></label>
            <ng-container *ngIf="receivingEntity !== 'SbrNexus'">
                <input type="text" id="receivingEntity" class="form-control" value="{{receivingEntity}}" disabled />
            </ng-container>
            <ng-container *ngIf="receivingEntity === 'SbrNexus'">
                <div ngbDropdown class="col dropdown" ngDefaultControl name="receivingEntityDropdown"
                    id="receivingEntityDropdown">
                    <button class="btn language dropdown-toggle text-truncate" type="button" id="dropdownMenu"
                        [ngClass]="{'required': receiverText === 'Request.View.Summary.Choose'}" ngbDropdownToggle>
                        {{ receiverName ? receiverName : receiverText | translate }} <em
                            class="bi bi-chevron-down float-end" aria-hidden="true"></em>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownMenu">
                        <button ngbDropdownItem *ngFor="let item of receivers" value="{{ item }}" type="button"
                            (click)="setReceiver(item.identityNumber, item.receiverName)">{{item.receiverName}}</button>
                    </div>
                </div>
            </ng-container>
        </span>
    </ng-container>
    <div class="mt-4 d-table w-100">
        <span class="float-start">
            <button type="button" class="btn btn-sm btn-outline-primary" (click)="back();" translate="Button.Back">
            </button>
        </span>
        <span class="float-end">
            <button type="button" class="btn btn-sm btn-success ms-1 order-1 float-end" (click)="clickFile();"
                [disabled]="!buttonEnabled" translate="Button.File">
            </button>
        </span>
    </div>
</div>