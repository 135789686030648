import { Component, Directive, OnDestroy, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { BadgeStatusEnum } from 'src/app/constants/badge-status.enum';
import { EventLogTypeEnum } from 'src/app/constants/event-log-type.enum';
import { EventLogApiClient, EventLogModel } from 'src/app/services/api-clients/event-log.api-client';
import { LoggingService } from 'src/app/services/logging.service';
import { RequestStatusService } from '../../requeststatus/requeststatus.service';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { StringHelper } from 'src/app/directives/string-helper';


@Directive() // required by angular to be able to use stuff like @ViewChild, although it is not used as a directive
export class BaseStatusLogComponentClass implements OnDestroy {
  /** 
   * must be earlier declared since its used for other properties
   * but protected as its just a TS helper (for the other implementations too)
   */
  protected stringHelper = new StringHelper();

  @ViewChild(MatSort) sort: MatSort = new MatSort();
  public dataSource!: MatTableDataSource<EventLogModel>;
  public eventlog!: EventLogModel[];
  public tableName = "Log";

  protected selectedRequestGuid!: string;
  protected eventLogSubscription: Subscription | null = null;
  protected requestGuidSubscription: Subscription | null = null;

  constructor(
    protected requestStatusService: RequestStatusService,
    protected eventLogApiClient: EventLogApiClient,
    protected loggingService: LoggingService,
    protected translateService: TranslateService
  ) {
    this.requestGuidSubscription = this.requestStatusService.selectedRequestId$.subscribe((requestGuid: string) => {
      this.selectedRequestGuid = requestGuid;
      this.eventLogSubscription = this.requestStatusService.eventLog$.subscribe(newEventLog => {
        this.eventlog = newEventLog; // for MOBILE
        this.dataSource = new MatTableDataSource(this.eventlog); // for desktop
        this.setupDefaultSorting(); // the set default sorting event (overridden for mobile)
      });
    });

  }

  setupDefaultSorting() {
    // if the datasource exist, use default sort
    if (this.dataSource) {
      let columnName = this.stringHelper.TypedNameProperty<EventLogModel>('occurenceTimestamp')
      const sortState: Sort = { active: columnName, direction: 'desc' };
      this.sort.start = sortState.direction;
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.dataSource.sort = this.sort;
      this.sort.sortChange.emit(sortState);
    }
  }
  ngOnDestroy() {
    if (this.eventLogSubscription) {
      this.eventLogSubscription.unsubscribe();
    }
  }

  public convertEventStatusToBadgeStatus(eventStatus: string): any {
    switch (eventStatus) {
      // info
      case EventLogTypeEnum.Created:
      case EventLogTypeEnum.Send:
      case EventLogTypeEnum.ReminderSend:
        return BadgeStatusEnum.Info;

      // warning
      case EventLogTypeEnum.ActorSigned:
        return BadgeStatusEnum.Warning;

      case EventLogTypeEnum.ActorInviteExpired:
        return BadgeStatusEnum.Danger;
        

      // success
      case EventLogTypeEnum.Withdrawn:
      case EventLogTypeEnum.RequestPurged:
        return BadgeStatusEnum.Success;

      // default bagde: info
      default:
        return BadgeStatusEnum.Info;
    }
  }

  public convertEventToTranslatedContent(event: EventLogModel): any {
    // if (event.eventType == EventLogTypeEnum.DeadlineEdited) {
    //   let formattedDate = formatDate(event.descriptionParametersZero, 'dd-MM-y HH:mm:ss', 'en-US');
    //   event.descriptionParametersZero = formattedDate;
    // }
    let paramZero: any = event.descriptionParametersZero;
    let paramZeroData: string | null = paramZero;

    // Specific DeadlineDaysEdited event format
    if (event.eventType == EventLogTypeEnum.DeadlineDaysEdited) {
      if (paramZero === 1) {
        paramZeroData = this.translateService.instant('Request.Status.DaysToReadableText.Selected_Day', { days: paramZero });
      }
      else if (paramZero > 1 && paramZero < 31) {
        paramZeroData = this.translateService.instant('Request.Status.DaysToReadableText.Selected_Days', { days: paramZero });
      }
      else if (paramZero === 31) {
        paramZeroData = this.translateService.instant('Request.Status.DaysToReadableText.Selected_Month', { month: 1 });
      }
      else if (paramZero > 31) {
        paramZeroData = this.translateService.instant('Request.Status.DaysToReadableText.Selected_Months', { months: paramZero === 62 ? 2 : 3 });
      }
      else {
        paramZeroData = paramZero;
      }
    }
    return this.translateService.instant('EventLog.' + event.eventType, { sourceName: event.sourceDisplayName, paramZero: paramZeroData, paramOne: event.descriptionParametersOne, paramTwo: event.descriptionParametersTwo });
  }
}


@Component({
  selector: 'mobile-status-log',
  templateUrl: './mobile_status_log.component.html',
  styleUrls: ['./table.component.scss']
})
export class MobileStatusLogComponent extends BaseStatusLogComponentClass {
  constructor(
    protected override requestStatusService: RequestStatusService,
    protected override eventLogApiClient: EventLogApiClient,
    protected override loggingService: LoggingService,
    protected override translateService: TranslateService
  ) {
    super(requestStatusService, eventLogApiClient, loggingService, translateService);
  }

  // for mobile we override the DEFAULT SORTING,since we do not care about the DATASOURCE
  // we use the array and ngFor
  override setupDefaultSorting() {
    if (this.eventlog) {
      // custom Desc sort for mobile, this is never changed
      this.eventlog.sort((loggingA, loggingB) => {
        if (loggingA.occurenceTimestamp > loggingB.occurenceTimestamp) {
          return -1
        }
        return 1
      });
    }
  }

}

@Component({
  selector: 'desktop-status-log',
  templateUrl: './desktop_status_log.component.html',
  styleUrls: ['./table.component.scss']
})

export class DesktopStatusLogComponent extends BaseStatusLogComponentClass {


  public displayedColumns: string[] = ['occurenceTimestamp'];

  constructor(
    protected override requestStatusService: RequestStatusService,
    protected override eventLogApiClient: EventLogApiClient,
    protected override loggingService: LoggingService,
    protected override translateService: TranslateService
  ) {
    super(requestStatusService, eventLogApiClient, loggingService, translateService)
    this.dataSource = new MatTableDataSource(this.eventlog);
  }
}