<ng-container *ngFor="let document of tableData">

  <div class="mb-3">
    <div class="rounded-corners">
    <table class="mat-mdc-table">
      <tr class="mat-mdc-header-row mobile">
        <th class="mat-mdc-header-cell mdc-data-table__header-cell" colspan="2">
          <span class="float-start mobile-title">{{document.name}}</span>

          <!-- Needs to be enabled when preview is posible on mobile-->

          
          <!-- <span class="float-end"
            *ngIf="isActionAllowed() && isPossibleToViewRendering(document)">
            <button type="button" class="btn bg-transparent border-0 p-0" data-bs-toggle="dropdown"
              aria-expanded="false">
              <i class="bi bi-three-dots bi-large"></i>
            </button>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item pt-1 pb-1" (click)="performActionForAnId('openPreview', document.id)"
                  translate="Button.ShowDocument"></a>
              </li>
            </ul>
          </span>
        </th> -->
      </tr>
      <ng-container *ngFor="let column of displayedColumns">
        <tr class="mat-mdc-row mobile cols-12" *ngIf="column.showMobile">
          <td class="mat-mdc-cell mdc-data-table__cell col-4 fw-bold" translate="Table.{{column.transTag}}"></td>
          <ng-container
            *ngIf="column.columnName !== 'documentType' && column.columnName !== 'fileSize' && column.columnName !== 'actions'">
            <td class="mat-mdc-cell mdc-data-table__cell col-8">
              {{document[column.columnName]}}
              <!-- Add copy to clipboard function, based on datatype Guid -->
              <ng-container *ngIf="column.type === 'Guid'">
                <em class="bi bi-files cursor-pointer copy-icon" aria-hidden="true" (click)="copyToClipboard(document[column.columnName])"></em>
              </ng-container>
            </td>
          </ng-container>
          <ng-container *ngIf="column.columnName === 'documentType'">
            <td class="mat-mdc-cell mdc-data-table__cell col-8"
              translate="Request.Document.{{document[column.columnName]}}"></td>
          </ng-container>
          <ng-container *ngIf="column.columnName === 'fileSize'">
            <td class="mat-mdc-cell mdc-data-table__cell col-8">{{document.fileSize | byteSizePipe}}</td>
          </ng-container>
        </tr>
      </ng-container>
    </table>
    </div>
  </div>

</ng-container>
