<div class="rounded-corners mat-elevation-z8">
    <table mat-table class="no-borders status-eventlog-list" [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="occurenceTimestamp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{tableName}} </th>
            <td mat-cell *matCellDef="let row">
                <div class="badge-column float-start"><app-badge-status-display
                        [badgeStatus]="convertEventStatusToBadgeStatus(row.eventType)"></app-badge-status-display></div>
                <div class="d-flex">{{row.occurenceTimestamp | date:'dd-MM-yy HH:mm'}} -
                    {{convertEventToTranslatedContent(row)}}</div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row class="small-row" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>